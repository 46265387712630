<template>
  <div>
    <p class="p-3 text-lg font-bold ">
      <span class="typcn typcn-edit"></span> Data Pelanggan
    </p>
    <hr class="mt-1 mb-1" />
    <form @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-sm-5 p-2" style="font-size: 12px">
          <div class="shadow p-3 rounded-lg bg-white">
            <!-- INPUT -->
            <div class="sm-form">
              <input
                type="text"
                id="id"
                name="id"
                class="form-control form-control-sm"
                placeholder="id"
                v-model="datapelangganhistory.id"
                disabled
              />
            </div>
            <table class="table table-sm table-responsive">
              <tr>
                <td class="capitalize">Pelanggan</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <v-select :options="pelanggans" label="nama" v-model="datapelangganhistory.pelanggan" :reduce="e => e.id"></v-select>
                </td>
              </tr>
               <tr>        
                  <td class="capitalize">tanggal</td>
                  <td class="pl-2 pr-2">:</td>
                  <td>
                      <div class="sm-form ">
                          <input type="date" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="datapelangganhistory.tanggal" >
                      </div>
                  </td>
              </tr>
               <tr>
                <td class="capitalize">Catatan oleh </td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <v-select :options="users" label="username" v-model="datapelangganhistory.create_by" :reduce="e => e.username"></v-select>
                </td>
              </tr>
               <tr>        
                  <td class="capitalize">catatan</td>
                  <td class="pl-2 pr-2">:</td>
                  <td>
                      <div class="sm-form">
                      <textarea
                      type="text"
                      id="catatan"
                      name="catatan"
                      rows="2"
                      placeholder="catatan..."
                      class="form-control md-textarea"
                      v-model="datapelangganhistory.catatan"
                      ></textarea>
                      </div>
                  </td>
              </tr>
              <tr>
                <td class="capitalize">Id transaksi </td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <v-select :options="transaksis" label="nota" v-model="datapelangganhistory.id_transaksi_nota" :reduce="e => e.nota"></v-select>
                </td>
              </tr>
            </table>
            <!-- END INPUT -->
            <!-- BUTTON PART -->
            <div class="mt-3 p-3">
              <button
                type="submit"
                class="btn btn-sm"
                :class="
                  btn == 'tambah'
                    ? 'btn-primary'
                    : btn == 'update'
                    ? 'btn-success'
                    : 'btn-danger'
                "
              >
                <span class="typcn typcn-info"></span> Proses {{ btn }}
              </button>
              <div class="float-right">
                <button
                  type="button"
                  v-show="btn != 'tambah'"
                  @click="
                    btn = 'tambah';
                    datapelangganhistory = {};
                  "
                  class="btn btn-sm btn-dark"
                >
                  <span class="typcn typcn-edit"></span> Tambah
                </button>
                <button
                  type="button"
                  @click="btn = 'delete'"
                  v-show="btn != 'tambah'"
                  class="ml-3 btn btn-sm btn-danger"
                >
                  <span class="typcn typcn-delete"></span> Delete
                </button>
              </div>
            </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class="col-sm-7 p-2" style="font-size: 12px">
          <div class="shadow bg-white rounded-lg">
            <div v-if="datanya.length > 0" class="p-2">
              <div class="">
 
                <vue-json-excel :data="datanya">
                  <button type="button" class="btn btn-sm btn-dark">
                    Export <span class="typcn typcn-chart-area"></span>
                  </button>
                </vue-json-excel>
              </div>
              <Btables
                :tables="datanya"
                :cari="['']"
                :hide="['']"
                @selected="ambil"
                ref="btable"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
    <div class="d-block d-sm-none"><br /><br /><br /><br /></div>
  </div>
</template>

<script>
// TOTURIAL
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  layout: "app_shop_admin",
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datapelangganhistory: {
      },
      btn: "tambah",
      datanya: [],
      pelanggans: [],
      users: [],
      transaksis:[],
    };
  },
  methods: {
    getData() {
      sdb
        .collection("app_kasir_pelanggan_history")
        .doc()
        .select(`select * from app_kasir_pelanggan_history`)
        .then((res) => {
          this.datanya = res;
          this.$forceUpdate();
        });
    sdb
        .collection("app_kasir_pelanggan")
        .doc()
        .select(`select * from app_kasir_pelanggan`)
        .then((res) => {
          this.pelanggans= res;
          this.$forceUpdate();
        });
    sdb
        .collection("tbuser")
        .doc()
        .select(`select * from tbuser`)
        .then((res) => {
          this.users= res;
          this.$forceUpdate();
        });
    sdb
        .collection("app_kasir_transaksi_nota")
        .doc()
        .select(`select * from app_kasir_transaksi_nota`)
        .then((res) => {
          this.transaksis= res;
          this.$forceUpdate();
        });
    
    },
    ambil(data) {
      this.datapelangganhistory = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    async submit() {
      let that = this;
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datapelangganhistory
          delete this.datapelangganhistory.id;
          await sdb
            .collection("app_kasir_pelanggan_history")
            .doc()
            .set(this.datapelangganhistory);
          that.getData();
          // that.$nuxt.$emit('getData')
        } else if (this.btn == "update") {
          await sdb
            .collection("app_kasir_pelanggan_history")
            .doc()
            .set(this.datapelangganhistory);
          that.getData();
          // that.$nuxt.$emit('getData')
        } else if (this.btn == "delete") {
          await sdb
            .collection("app_kasir_pelanggan_history")
            .doc(this.datapelangganhistory.id)
            .delete();
          that.getData();
          // that.$nuxt.$emit('getData')
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>